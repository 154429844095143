import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointService } from '@bmng/services/endpoint.service';
import { LangService } from '@kognitiv/angular-i18n';
import { Observable } from 'rxjs';

import { AirbnbConnectionStatus, AirbnbMappingData, AirbnbMappingInformation, AirbnbMappingStatus, AirbnbPropertyDefaults, AirbnbRoom } from './interfaces/airbnb.interface';
import { AirbnbServiceInterface } from './interfaces/airbnb.service.interface';

@Injectable()
export class AirbnbService extends EndpointService implements AirbnbServiceInterface {
    private static BASE_URL: string = `${EndpointService.getBmBackendUrl()}/api/wrapper-airbnb`;

    constructor(
        private langService: LangService,

        protected http: HttpClient,
    ) {
        super(http);
    }

    getConnectionStatus(hotelId: string): Observable<AirbnbConnectionStatus> {
        const url = `${this.getBaseUrl(hotelId)}/connection`;

        return this.httpGet(url);
    }

    updatePropertyDefaults(hotelId: string, defaults: AirbnbPropertyDefaults): Observable<boolean> {
        const url = `${this.getBaseUrl(hotelId)}/propertyDefaults`;

        return this.httpPost(url, defaults, EndpointService.HTTP_HEADERS);
    }

    getMappingInformation(hotelId: string, roomId: string): Observable<AirbnbMappingInformation> {
        const url = `${this.getBaseUrl(hotelId)}/mappingInformation/${roomId}`;

        return this.httpGet(url);
    }

    getRoomsList(hotelId: string): Observable<AirbnbRoom[]> {
        const url = `${this.getBaseUrl(hotelId)}/mapping`;

        return this.httpGet(url);
    }

    getMappingStatus(hotelId: string, roomId: string): Observable<AirbnbMappingStatus> {
        const url = `${this.getBaseUrl(hotelId)}/mapping/${roomId}`;

        return this.httpGet(url);
    }

    updateMappingStatus(hotelId: string, roomId: string, data: AirbnbMappingData): Observable<boolean> {
        const url = `${this.getBaseUrl(hotelId)}/mapping/${roomId}`;

        return this.httpPost(url, data, EndpointService.HTTP_HEADERS);
    }

    refresh(hotelId: string): Observable<boolean> {
        const url = `${this.getBaseUrl(hotelId)}/refresh`;

        return this.httpPost(url, {}, EndpointService.HTTP_HEADERS);
    }

    private getBaseUrl(hotelId: string): string {
        const lang = this.langService.getCurrentLanguage();
        return `${AirbnbService.BASE_URL}/${lang}/${hotelId}`;
    }
}
