import { ActivatedRoute } from '@angular/router';

import { PageConfig } from '../../core/services/page-config.interface';
import { PageService } from '../../core/services/page.service';

export class MasterPage {

    constructor(
      public pageService: PageService,
      public route: ActivatedRoute,
    ) {
    }

    initPage(config: PageConfig): void {
        // TODO Extend with a per-page config
        config.onlyPageContents = config.onlyPageContents || this.pageService.isFullscreenFromRoute(this.route);

        this.pageService.triggerNewPage(config);
    }
}
