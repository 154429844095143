import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { Observable } from 'rxjs';
import { EndpointService } from '../endpoint.service';
import { Description, DescriptionType } from './interfaces/descriptions.interface';

@Injectable()
export class DescriptionsService extends EndpointService {
    constructor(
        http: HttpClient,
        private lang: LangService,
    ) {
        super(http);
    }

    getDescriptions(hotelId: string): Observable<Description[]> {
        const url = UrlHelpers.buildUrl(this.getBaseUrl(hotelId), {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    getDescription(hotelId: string, type: DescriptionType, id: number): Observable<Description> {
        const url = UrlHelpers.buildUrl(`${this.getBaseUrl(hotelId)}/${type}/${id}`, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }

    updateDescription(hotelId: string, description: Description): Observable<void> {
        const url = UrlHelpers.buildUrl(`${this.getBaseUrl(hotelId)}/${description.type}/${description.code}`, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPut(url, description, EndpointService.HTTP_HEADERS);
    }

    private getBaseUrl(hotelId: string): string {
        return `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/descriptions`;
    }
}
