import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { LangService } from '@kognitiv/angular-i18n';

import { PageService } from '../../../core/services/page.service';

@Component({
    templateUrl: './error.component.html',
    styleUrls: [ './error.component.scss' ],
})
export class ErrorComponent implements OnInit {
    errorTitle: string = '';
    errorMessage: string = '';

    constructor(
        private pageService: PageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private langService: LangService,
    ) {}

    ngOnInit(): void {
        this.initError();

        this.pageService.triggerNewPage({
            title: this.errorTitle,
            breadcrumbConfigs: [],
        });
    }

    returnToRoot(): void {
        this.router.navigate([ '/' ]);
    }

    private initError(): void {
        const errorTitle = this.activatedRoute.snapshot.queryParams.title;
        const errorMessage = this.activatedRoute.snapshot.queryParams.message;
        const errorCode: 'NOT_FOUND' | undefined = RouteHelpers.getDataFromRoute(this.activatedRoute.snapshot, 'error');

        if (errorTitle || errorMessage) {
            this.errorTitle = errorTitle;
            this.errorMessage = errorMessage;
        } else if (errorCode === 'NOT_FOUND') {
            this.errorTitle = this.langService.get('bmng.errors.404.title');
            this.errorMessage = this.langService.get('bmng.errors.404.message');
        } else {
            this.errorTitle = this.langService.get('seekda.errors.generalErrorTitle');
            this.errorMessage = this.langService.get('onboarding.general.errorLoading');
        }
    }
}
