import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { DataProviderConfig } from '@kognitiv/bm-components';
import { map, Observable } from 'rxjs';

import { EndpointData } from '../endpoint-data.interface';
import { UndeterminedListResult } from '../list-results.interface';
import { EndpointService } from './../endpoint.service';
import { Channel, ChannelCustomQuestion, ChannelFreeTrial, ChannelSetting, ChannelSettingValue, ChannelsListForPortal, ChannelType, ChannelWithSettings, GlobalChannel } from './interfaces/channel';
import { ChannelSetup } from './interfaces/channel-setup.interface';
import { ChannelsChannel, ChannelsChannelPatch } from './interfaces/channels-channel.interface';
import { ChannelsServiceInterface } from './interfaces/channels.service.interface';
import { PullInfo } from './interfaces/pull-info.interface';

export interface ChannelParams {
    enabled?: boolean;
    hidden?: boolean;
    clearCache?: boolean;
    types?: ChannelType[];
}

export type EnabledSource = 'ENABLED' | 'DISABLED' | 'BOTH';
export type HiddenSource = 'VISIBLE' | 'HIDDEN' | 'BOTH';

@Injectable()
export class ChannelsService extends EndpointService implements ChannelsServiceInterface {
    public static readonly COMMON_CHANNEL_TYPES: ChannelType[] = [ 'CPC', 'CPA', 'Direct', 'Portal', 'OtherMeta', 'Product' ];

    constructor(
        http: HttpClient,
        private lang: LangService,
    ) {
        super(http);
    }

    createCircleContext(hotelId: string, entityId: string): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotels/${hotelId}/bookingengines/partnerContext`;
        const data = {
            applicationType: 'search',
            instanceId: entityId,
        };

        return this.httpPost<boolean>(url, data, EndpointService.HTTP_HEADERS);
    }

    getChannelListForUser(): Observable<GlobalChannel[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/api/channels`;
        return this.httpGet<GlobalChannel[]>(url, EndpointService.HTTP_HEADERS);
    }

    /**
     * @deprecated Use either the form with string parameters or getChannelsByParams
     */
    getChannels(
        hotelId: string,
        enabled: boolean,
        hidden?: boolean,
        clearCache?: boolean,
        types?: ChannelType[],
        config?: DataProviderConfig,
    ): Observable<Channel[]>;
    getChannels(
        hotelId: string,
        enabled?: EnabledSource,
        hidden?: HiddenSource,
        clearCache?: boolean,
        types?: ChannelType[],
        config?: DataProviderConfig,
    ): Observable<Channel[]>;
    getChannels(
        hotelId: string,
        enabled: boolean | EnabledSource = 'BOTH',
        hidden: boolean | HiddenSource = 'VISIBLE',
        clearCache?: boolean,
        types: ChannelType[] = [],
        config?: DataProviderConfig,
    ): Observable<Channel[]> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/channels`;
        const params: { [key: string]: unknown } = {};

        if (enabled !== 'BOTH') {
            params.enabled = typeof enabled === 'boolean' ? enabled : enabled === 'ENABLED';
        }
        if (hidden !== 'BOTH') {
            params.hidden = typeof hidden === 'boolean' ? hidden : hidden === 'HIDDEN';
        }
        if (typeof clearCache !== 'undefined') {
            params.clearCache = clearCache;
        }
        params.type = types;

        let tableConfigParams = {};
        if (!!config) {

            // 'type' filter should be mapped to a params.type param
            if (config.filters) {
                if (config.filters.type) {
                    params.type = config.filters.type;
                }
            }
            tableConfigParams = {
                q: config.searchTerm,
                sort: config.sortCol,
                sortType: config.sortDir,
                size: config.resultsPerPage,
                start: config.page * config.resultsPerPage,
                filters: config.filters,
            };
        }

        const allParams = {
            ...params,
            ...tableConfigParams,
        };
        const url = UrlHelpers.buildUrl(baseUrl, allParams);
        return this.httpGet<Channel[]>(url, EndpointService.HTTP_HEADERS);
    }
    getChannelForPortal(
        chainId: string,
        types?: ChannelType[],
        config?: DataProviderConfig): Observable<ChannelsListForPortal> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/cm/cpcchain/${chainId}/channels/details`;
        const params: { [key: string]: unknown } = {};

        params.type = types.map(t => t.toLocaleLowerCase());

        const tableConfigParams = {};
        // if (!!config) {

        //     // 'type' filter should be mapped to a params.type param
        //     if (config.filters) {
        //         if (config.filters.type) {
        //             params.type = config.filters.type;
        //         }
        //     }
        //     tableConfigParams = {
        //         q: config.searchTerm,
        //         sort: config.sortCol,
        //         sortType: config.sortDir,
        //         size: config.resultsPerPage,
        //         start: config.page * config.resultsPerPage,
        //         filters: config.filters,
        //     };
        // }

        const allParams = {
            ...params,
            ...tableConfigParams,
        };
        const url = UrlHelpers.buildUrl(baseUrl, allParams);
        return this.httpGet<ChannelsListForPortal>(url, EndpointService.HTTP_HEADERS);
    }

    getChannelsFromPropertiesOfMaster(
        hotelId: string,
        enabled: boolean = undefined,
        hidden: boolean = undefined,
        clearCache: boolean = undefined,
        types: ChannelType[] = [],
        languageCode: string = undefined,
    ): Observable<Channel[]> {
        let url = `${EndpointService.getBmBackendUrl()}/api/cm/api/masterproperty/${hotelId}/channels`;
        let params = [];

        if (typeof enabled !== 'undefined') {
            params.push('enabled=' + enabled);
        }
        if (typeof hidden !== 'undefined') {
            params.push('hidden=' + hidden);
        }
        if (typeof clearCache !== 'undefined') {
            params.push('clearCache=' + clearCache);
        }
        if (typeof languageCode !== 'undefined') {
            params.push('languageCode=' + languageCode);
        }
        params = params.concat(types.map(type => 'type=' + type));

        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        return this.httpGet<Channel[]>(url, EndpointService.HTTP_HEADERS);
    }

    /**
     * This methods puts the list of parameters into a simple object. It allows you to simply set the parameters
     * you need to set without providing `undefined` as parameters.
     * This method is also supposed to be used if you want to query channels which are intended to be used as filters
     * (eg. in access restrictions, promotions, etc.)
     *
     * @param hotelId The property code
     * @param params An object containing the proper channel endpoint parameters. Defaults to common channel
     * types for booking access restrictions.
     */
    getChannelsByParams(hotelId: string, params: ChannelParams = {
        enabled: true,
        hidden: false,
        types: ChannelsService.COMMON_CHANNEL_TYPES,
    }): Observable<Channel[]> {

        [ 'enabled', 'hidden', 'clearCache', 'types' ].forEach(key => {
            params[key] = (typeof params[key] === 'undefined' || params[key] === null) ? undefined : params[key];
        });

        return this.getChannels(hotelId, params.enabled, params.hidden, params.clearCache, params.types);
    }

    getChannel(hotelId: string, channel: string): Observable<Channel> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/channels/${channel}`;

        return this.httpGet<Channel>(url);
    }

    setChannel(hotelId: string, channel: string, isEnabled: boolean, isHidden: boolean): Observable<Channel[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/channels/${channel}`;

        return this.httpPost<never>(url, { enabled: isEnabled, hidden: isHidden }, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    getChannelSetups(hotelId: string, ...channelIds: string[]): Observable<{ [channelId: string]: ChannelSetup }> {
        let url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/channels/setup`;
        url += '?channelId=' + channelIds.join('&channelId=');

        return this.httpGet<{ [channelId: string]: ChannelSetup }>(url, EndpointService.HTTP_HEADERS_ACCEPT);
    }

    setChannelSetup(hotelId: string, channelId: string, newSetup: { [channelSetupKey: string]: string }): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/channels/${channelId}/setup`;

        return this.httpPost<boolean>(url, newSetup, EndpointService.HTTP_HEADERS);
    }

    getChannelSettingsForPortal(portalId: string): Observable<ChannelWithSettings> {
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/${portalId}`;
        return this.httpGet<ChannelWithSettings | UndeterminedListResult<ChannelWithSettings>>(url, EndpointService.HTTP_HEADERS).pipe(
            map(result => {
                if (this.isChannelWithSettings(result)) {
                    return result;
                }

                if (result.result.length !== 1) {
                    throw new Error(`Found ${result.result.length} hotels, expected 1`);
                }
                return result.result[0];
            }),
        );
    }
    getChannelSettings(hotelId: string, channelId: string): Observable<ChannelWithSettings> {
        // TODO Remove after endpoint has been fixed to not return list response
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/hotels/${hotelId}`;
        return this.httpGet<ChannelWithSettings | UndeterminedListResult<ChannelWithSettings>>(url, EndpointService.HTTP_HEADERS).pipe(
            map(result => {
                if (this.isChannelWithSettings(result)) {
                    return result;
                }

                if (result.result.length !== 1) {
                    throw new Error(`Found ${result.result.length} hotels, expected 1`);
                }
                return result.result[0];
            }),
        );
    }

    getCircleSettings(channelId: string): Observable<ChannelWithSettings> {
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}`;
        return this.httpGet<ChannelWithSettings | UndeterminedListResult<ChannelWithSettings>>(url, EndpointService.HTTP_HEADERS).pipe(
            map(result => {
                if (this.isChannelWithSettings(result)) {
                    return result;
                }

                if (result.result.length !== 1) {
                    throw new Error(`Found ${result.result.length} hotels, expected 1`);
                }
                return result.result[0];
            }),
        );
    }


    setChannelSettings(
        hotelId: string, channelId: string, settings: ChannelSetting[], customQuestions?: ChannelCustomQuestion[]): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/hotels/${hotelId}`;

        const entry: Partial<ChannelWithSettings>[] = [
            {
                id: hotelId,
                settings,
                customQuestions,
            },
        ];

        return this.httpPatch<boolean>(url, entry, EndpointService.HTTP_HEADERS);
    }

    setCircleSettings(channelId: string, settings: ChannelSetting[]): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}`;

        const entry: Partial<ChannelWithSettings> =
            {
                id: channelId,
                settings,
            };

        return this.httpPatch<boolean>(url, entry, EndpointService.HTTP_HEADERS);
    }


    setChannelSettingsForPortal(portalId: string, settings: ChannelSetting[]): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/${portalId}`;

        const entry: Partial<ChannelWithSettings> = {
            id: portalId,
            settings,
        };

        return this.httpPatch<boolean>(url, entry, EndpointService.HTTP_HEADERS);
    }

    getPullInfo(hotelIds: string[]): Observable<PullInfo> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/pull/info`;
        const url = UrlHelpers.buildUrl(baseUrl, { hotelId: hotelIds });

        return this.httpGet<PullInfo>(url, EndpointService.HTTP_HEADERS);
    }

    getChannelsForChannel(channelId: string, searchTerm: string, showHidden: boolean, types: ChannelType[]): Observable<ChannelsChannel[]> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/channels`;
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
            searchTerm,
            showHidden,
            type: types,
        });

        return this.httpGet<ChannelsChannel[]>(url, EndpointService.HTTP_HEADERS);
    }

    patchChannelsForChannel(channelId: string, channelIdToChange: string, patchData: ChannelsChannelPatch):
        Observable<EndpointData<boolean>> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/channels/${channelIdToChange}`;
        const url = UrlHelpers.buildUrl(baseUrl, {
            languageCode: this.lang.getCurrentLanguage(),
        });

        return this.httpPatchWithFullData<boolean>(url, patchData, EndpointService.HTTP_HEADERS);
    }

    getChildrenForChannel(channelId: string): Observable<string[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/${channelId}/children`;

        return this.httpGet<string[]>(url, EndpointService.HTTP_HEADERS);
    }

    getSettingsValue<T extends ChannelSettingValue>(settings: ChannelSetting[], code: string, defaultValue: T): T {
        return <T> settings.find(s => s.code === code)?.value ?? defaultValue;
    }

    getFreeTrialInfo(hotelId: string, channelIds: string[]): Observable<ChannelFreeTrial[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/channels/properties/${hotelId}/trialPeriods`;
        return this.httpGet<ChannelFreeTrial[]>(url, EndpointService.HTTP_HEADERS);
    }

    private isChannelWithSettings(result: ChannelWithSettings | UndeterminedListResult<ChannelWithSettings>):
        result is ChannelWithSettings {
        return !((<UndeterminedListResult<ChannelWithSettings>> result)?.result);
    }
}
