import { Pipe, PipeTransform } from '@angular/core';
import { ImageResultAlternativeSize, PossibleImageAlternativeSizeCategory, PossibleImageAlternativeSizeSize } from '@bmng/services/images/interfaces/images.interface';

@Pipe({
    name: 'imgUrl',
})
export class ImageUrlPipe implements PipeTransform {
    transform(img: {
            url: string;
            alternativeSizes?: ImageResultAlternativeSize[];
        },
        category: PossibleImageAlternativeSizeCategory,
        size: PossibleImageAlternativeSizeSize): string {

        if (!img) {
            return;
        }

        if (!img.alternativeSizes) {
            return img.url;
        }

        const alternativeSize = img.alternativeSizes
            .filter(altSize => altSize.category === category)
            .filter(altSize => altSize.size === size);

        if (alternativeSize.length) {
            return alternativeSize[0].url;
        }

        return img.url;
    }
}
