import { ElementRef, Injectable } from '@angular/core';
import { fromEvent, NEVER, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

declare const window: Window;

export interface UnloadEvent {
    returnValue: string;
    preventDefault(): void;
}

export type WindowWidth = number;

export type WindowHeight = number;

export type UnloadCallback = (e: UnloadEvent) => string | null;

@Injectable({
    providedIn: 'root',
})
export class WindowListenerService {
    public readonly DEBOUNCE_TIME_MS = 100;

    private scroll$: Observable<void>;

    public get windowWidth(): number {
        return window.innerWidth;
    }

    public get windowHeight(): number {
        return window.innerHeight;
    }

    public onBeforeUnload(): Observable<UnloadEvent> {
        return new Observable(subscriber => {
            const fn: UnloadCallback = evt => {
                subscriber.next(evt);
                return null;
            };

            return this.addOnUnloadHandler(fn);
        });
    }

    /**
     * Add an onBeforeUnload handler to the window. If you return a truthy value in the callback,
     * the user will need to confirm that they want to leave the page.
     *
     * @param callback The callback to be executed when the window is about to be unloaded.
     * @returns A function that removes the event listener.
     *
     * Note: If you only need to do something when the user leaves the page (ie you always `return null`),
     * you can simply use the onUnload() method.
     */
    public addOnUnloadHandler(callback: UnloadCallback): () => void {
        window.addEventListener('beforeunload', callback);

        return () => {
            window.removeEventListener('beforeunload', callback);
        };
    }

    /**
     * Returns true if element is at least partly in boundaries of view port. Keep in mind that this causes
     * layout events!
     */
    public isElementInViewPort(element: ElementRef): boolean {
        if (!element || !element.nativeElement) {
            return false;
        }

        const vpHeight = window.innerHeight;
        const vpWidth = window.innerWidth;
        const clientRect = element.nativeElement.getBoundingClientRect();

        if (!clientRect) {
            return false;
        }

        const isVisible = ((clientRect.left >= 0 && clientRect.left <= vpWidth) ||
            (clientRect.right >= 0 && clientRect.right <= vpWidth)) &&
            ((clientRect.top >= 0 && clientRect.top <= vpHeight) ||
                (clientRect.bottom >= 0 && clientRect.bottom <= vpHeight));

        return isVisible;
    }

    public scroll(): Observable<void> {
        if (!this.scroll$) {
            const element = window.document.querySelector('.content-container');
            if (!!element) {
                this.scroll$ = fromEvent(element, 'scroll').pipe(
                    debounceTime(100),
                    map(_ => null),
                );
            } else {
                this.scroll$ = NEVER;
            }
        }
        return this.scroll$;
    }

    public resize(): Observable<WindowWidth> {
        return fromEvent(window, 'resize').pipe(
            debounceTime(this.DEBOUNCE_TIME_MS),
            map(_ => [ window.innerWidth, window.innerHeight ]),
            distinctUntilChanged((prev, cur) => (prev[0] === cur[0] && prev[1] === cur[1])),
            map(([ width, _ ]: [number, number]) => width),
        );
    }

    public width(): WindowWidth {
        return window.innerWidth;
    }

    public height(): WindowHeight {
        return window.innerHeight;
    }
}
