import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChannelSettingValue } from '@bmng/services/channels/interfaces/channel';
import {
    DynamicFormField,
    DynamicFormItemType,
    DynamicFormValue,
    DynamicFormValueType,
} from '@bmng/services/dynamic-form/interfaces/dynamic-form';
import { assertNever, SelectOption } from '@kognitiv/bm-components';

class FormItemDefinition {
    type: DynamicFormItemType;
    isInteger = false;
    enumValues = null;
    value: DynamicFormValueType;

    constructor(type: DynamicFormItemType) {
        this.type = type;
    }
}

export interface DynamicFormParentForm {
    dynamicFormControls: FormArray<FormGroup<DynamicFormGroup>>;
}

export interface DynamicFormGroup {
    formItemName: FormControl<string>;
    formItemCode: FormControl<string>;
    formItemEncrypted: FormControl<boolean>;
    formItemRequired: FormControl<boolean>;
    formItemType: FormControl<DynamicFormItemType>;
    formItemInteger: FormControl<boolean>;
    formItemEnumValues: FormControl<SelectOption[]>;
    formItemDescription: FormControl<string>;
    formItemSao: FormControl<boolean>;
    formItemReadOnly: FormControl<boolean>;
    formItemMaxLength: FormControl<number>;
    formItemValue: FormControl<ChannelSettingValue>;
    formItemOriginalValue: FormControl<ChannelSettingValue>;
}

@Injectable()
export class DynamicFormUIService {
    createForm(configuration: DynamicFormField[], values: DynamicFormValue[]): FormGroup<DynamicFormParentForm> {
        const form = new FormGroup<DynamicFormParentForm>({ dynamicFormControls: new FormArray([]) });

        if (configuration?.length > 0) {
            configuration.forEach(formItem => {
                const formItemDefinition = this.getDefinition(formItem, values);

                if (formItemDefinition) {
                    let value = formItemDefinition.value;

                    if (formItemDefinition.type === 'Duration' && !formItemDefinition.value) {
                        value = '';
                    }

                    const itemGroup: FormGroup<DynamicFormGroup> = new FormGroup({
                        formItemName: new FormControl(formItem.label ? formItem.label : formItem.description),
                        formItemCode: new FormControl(formItem.code),
                        formItemEncrypted: new FormControl(formItem.secret),
                        formItemRequired: new FormControl(!!formItem.required),
                        formItemType: new FormControl(formItemDefinition.type),
                        formItemInteger: new FormControl(formItemDefinition.isInteger),
                        formItemEnumValues: new FormControl(formItemDefinition.enumValues),
                        formItemDescription: new FormControl(formItem.label ? formItem.description : ''),
                        formItemSao: new FormControl(formItem.isSAO),
                        formItemReadOnly: new FormControl(formItem.readOnly),
                        formItemMaxLength: new FormControl(formItem.maxLength),

                        formItemValue: new FormControl(
                            { value, disabled: !!formItem.readOnly },
                            [
                                (!!formItem.required && !formItem.secret) ? Validators.required : null,
                                formItem.maxLength ? Validators.maxLength(formItem.maxLength) : null,
                            ].filter(v => v),
                        ),
                        formItemOriginalValue: new FormControl(value),
                    });

                    (<FormArray<FormGroup<DynamicFormGroup>>>form.get('dynamicFormControls')).push(itemGroup);
                }
            });
        }

        return form;
    }

    updateFormValues(form: FormArray<FormGroup<DynamicFormGroup>>, values: DynamicFormValue[]): void {
        form.controls.forEach(ctrl => {
            const code = ctrl.get('formItemCode').value;

            const formValue = values.find(f => f.code === code);

            ctrl.get('formItemValue').setValue(formValue.value, { emitEvent: false });
            ctrl.get('formItemOriginalValue').setValue(formValue.value, { emitEvent: false });
        });
    }

    private getDefinition(formItem: DynamicFormField, values: DynamicFormValue[]): FormItemDefinition | undefined {
        const value = values.find(f => f.code === formItem.code);

        switch (formItem.type) {
            case 'Boolean':
                return this.getBooleanDefinition(formItem, value);

            case 'Decimal':
            case 'Integer':
                return this.getNumberDefinition(formItem, value);

            case 'Enumeration':
                return this.getEnumDefinition(formItem, value);

            case 'String':
            case 'TextArea':
                return this.getStringDefinition(formItem, value);

            case 'Duration':
                return this.getDurationDefinition(formItem, value);

            case 'StringArray':
                return this.getArrayDefinition(formItem, value);

            default:
                try {
                    assertNever(formItem.type);
                } catch (e) {
                    // Don't fail on assertNever
                    console.warn('Could not show unknown form item of type', formItem.type);
                    return undefined;
                }
        }
    }

    private getBooleanDefinition(formItem: DynamicFormField, formValue: DynamicFormValue): FormItemDefinition {
        const definition = new FormItemDefinition(formItem.type);

        if (formValue?.value === true || formValue?.value === false || formValue?.value == null) {
            definition.value = formValue ? !!formValue.value : false;
        } else {
            console.error('Dynamic Form - Value is not boolean');
        }

        return definition;
    }

    private getEnumDefinition(formItem: DynamicFormField, formValue: DynamicFormValue): FormItemDefinition {
        const definition = new FormItemDefinition(formItem.type);

        if (typeof formValue?.value === 'string' || formValue?.value == null) {
            definition.enumValues = formItem.enumValues ?? [];

            let value: string = null;

            if (formValue && formValue.value != null
                && (<SelectOption[]>definition.enumValues).find(o => o.id === formValue.value)) {
                value = <string>formValue.value;
            }

            definition.value = value;
        } else {
            console.error('Dynamic Form - Value is not string');
        }

        return definition;
    }

    private getNumberDefinition(formItem: DynamicFormField, formValue: DynamicFormValue): FormItemDefinition {
        const definition = new FormItemDefinition(formItem.type);

        if (typeof formValue?.value === 'bigint' || typeof formValue?.value === 'number' || formValue?.value == null) {
            definition.isInteger = formItem.type === 'Integer';
            definition.value = formValue && (formValue.value || formValue.value === 0) ? formValue.value : null;
        } else {
            console.error('Dynamic Form - Value is not number');
        }

        return definition;
    }

    private getStringDefinition(formItem: DynamicFormField, formValue: DynamicFormValue): FormItemDefinition {
        const definition = new FormItemDefinition(formItem.type);

        definition.value = formValue && formValue.value ? formValue.value.toString() : '';

        return definition;
    }

    private getDurationDefinition(formItem: DynamicFormField, formValue: DynamicFormValue): FormItemDefinition {
        const definition = new FormItemDefinition(formItem.type);

        definition.value = formValue && (formValue.value || formValue.value === 0) ? formValue.value : null;

        return definition;
    }

    private getArrayDefinition(formItem: DynamicFormField, formValue: DynamicFormValue): FormItemDefinition {
        const definition = new FormItemDefinition(formItem.type);

        definition.value = formValue?.value ?? [];
        definition.enumValues = formItem.enumValues ?? [];

        return definition;
    }
}

